import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { CreateOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/admin/dashboard/create-project"); // Ajusta la ruta a la que deseas redirigir
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="PAGINA PRINCIPAL" subtitle="Bienvenido" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={handleButtonClick}
          >
            <CreateOutlined sx={{ mr: "10px" }} />
            Crear Proyecto
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
