import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import logo from "../assets/footer.png";
import logoRight from "../assets/footer1.png";
import foto5 from "../assets/foto5.jpg";
import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import { MusicNoteRounded } from "@mui/icons-material";

const logoStyle = {
  width: "140px",
  height: "auto",
};

const logoStyle1 = {
  width: "200px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography variant="body2" color="#ffff" mt={1}>
      {"Copyright © "}
      <Link href="/" fontWeight={600} color={"#000"}>
        IntiLigth&nbsp;
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      id="footer"
      sx={{
        position: "relative",
        width: "100%",
        backgroundImage: `url(${foto5})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        py: { xs: 8, sm: 10 },
        color: "white", // Para asegurar que el texto sea legible sobre la imagen de fondo
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Color negro semitransparente para la superposición
          zIndex: 1,
        }}
      />
      <Container
        sx={{
          fontFamily: "FuturaStd-Medium",
          position: "relative",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 4, sm: 8 },
          textAlign: { sm: "center", md: "left" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              minWidth: { xs: "100%", sm: "60%" },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
              <Box sx={{ ml: { xs: "-10px" } }}>
                <img src={logo} style={logoStyle} alt="logo of sitemark" />
              </Box>
              <Typography
                fontFamily={"FuturaStd-Medium"}
                fontWeight={600}
                gutterBottom
              >
                SOMOS INTILIGTH EC
              </Typography>
              <Typography mb={2} pr={2} sx={{ fontFamily: "FuturaStd-Medium" }}>
                Cotiza con nosotros
              </Typography>
              <Typography
                mb={2}
                sx={{
                  fontFamily: "FuturaStd-Medium",
                  pl: { md: "0px", sm: "40px" },
                }}
              >
                ventas_info@intilightec.com
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: { xs: "100%", sm: "40%" },
              mt: { xs: 4, sm: 0 },
            }}
          >
            <img src={logoRight} style={logoStyle1} alt="logo intilight" />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: { xs: 4, sm: 8 },
            width: "100%",
            borderTop: "1px solid",
            borderColor: "divider",
          }}
        >
          <div>
            <Link color="#ffff" href="#">
              Políticas de privacidad
            </Link>
            <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
              &nbsp;•&nbsp;
            </Typography>
            <Link color="#ffff" href="#">
              Términos de servicio
            </Link>
            <Copyright />
          </div>
          <Stack
            direction="row"
            justifyContent="left"
            spacing={1}
            useFlexGap
            sx={{
              color: "#ffff",
            }}
          >
            <IconButton
              color="inherit"
              href="https://www.facebook.com/people/IntilightEc/61560430589282/?mibextid=LQQJ4d"
              target="_blank"
              aria-label="Facebook"
              sx={{ alignSelf: "center" }}
            >
              <FacebookIcon />
            </IconButton>

            <IconButton
              color="inherit"
              href="https://www.tiktok.com/@intilightec?is_from_webapp=1&sender_device=pc"
              target="_blank"
              aria-label="LinkedIn"
              sx={{ alignSelf: "center" }}
            >
              <MusicNoteRounded />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
