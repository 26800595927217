import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import CardProduct from "./Productos/CardProduct";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);
  const { obtenerProductos } = React.useContext(AuthContext);

  const boton = (event) => {
    event.preventDefault();

    obtenerProductos();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Más sobre IntiLight Ec
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Qué es Energía Renovable ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              La energía renovable proviene de fuentes naturales que se
              regeneran de manera continua y no se agotan con el uso.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Ventajas y desventajas de energía renovable y eléctrica
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              La energía renovable ofrece ventajas significativas como la
              sostenibilidad y la reducción de emisiones contaminantes, ya que
              utiliza fuentes inagotables como el sol y el viento, lo que
              disminuye la dependencia de los combustibles fósiles y reduce los
              costos operativos a largo plazo. Sin embargo, presenta desafíos
              como la intermitencia y los altos costos iniciales de instalación,
              además de requerir grandes áreas de terreno para su
              implementación. Por otro lado, la energía eléctrica convencional
              es confiable y cuenta con una infraestructura establecida y costos
              iniciales más bajos, pero su dependencia de combustibles fósiles
              genera emisiones de gases de efecto invernadero, contribuyendo al
              cambio climático y al agotamiento de recursos naturales finitos,
              además de tener un impacto ambiental considerable.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Soporte ajustado
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              El soporte ajustado a su necesidad se refiere a la capacidad de
              nosotros como empresa Intilightec para ofrecer soluciones
              personalizadas que se adapten específicamente a los requerimientos
              de cada cliente.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Garantía de los servicios de IntiLight Ec
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              La garantía de IntilightEc asegura que los productos y servicios
              proporcionados están respaldados por un compromiso de calidad y
              satisfacción del cliente. Esto significa que, en caso de que
              surjan problemas o defectos dentro del período de garantía
              especificado, IntilightEc se compromete a repararlos o
              reemplazarlos sin costo adicional para el cliente.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
