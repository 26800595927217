import { useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";

import Form from "./scenes/form";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import LandingPage from "./components/LandingPage";
import Login from "./components/Login";
import DatosCliente from "./components/DatosCliente";
import { AuthContext } from "./Context/AuthContext";
import ResumenDatosCliente from "./components/ResumenDatosCliente";
import Tienda from "./components/Tienda";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { userToken } = useContext(AuthContext);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {userToken !== null ? (
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar} />
              <Routes>
                <Route
                  path="admin/*"
                  element={
                    <Routes>
                      <Route path="dashboard" index element={<Dashboard />} />
                      <Route
                        path="dashboard/create-project"
                        element={<DatosCliente />}
                      />
                      <Route
                        path="dashboard/create-project/resumen"
                        element={<ResumenDatosCliente />}
                      />
                      <Route path="team" element={<Team />} />
                      <Route path="contacts" element={<Contacts />} />

                      <Route path="form" element={<Form />} />

                      <Route path="*" element={<Dashboard />} />
                    </Routes>
                  }
                />
              </Routes>
            </main>
          </div>
        ) : (
          <Routes>
            <Route index element={<LandingPage />} />

            <Route path="/">
              <Route path="login" element={<Login />} />
              <Route path="project" element={<DatosCliente />} />
              <Route path="tienda" element={<Tienda />} />
            </Route>
          </Routes>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
