import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Header from "../../components/Header";

function Form() {
  const theme = useTheme();

  const [productData, setProductData] = useState({
    nombre: "",
    stock: 0,
    descripcion: "",
    categoria: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  // Función para cerrar el Snackbar
  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("http://localhost:1337/api/categorias");
        const data = await response.json();
        setCategories(data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (event) => {
    setProductData({
      ...productData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validar que todos los campos estén llenos
    if (
      !productData.nombre.trim() ||
      productData.stock <= 0 ||
      !productData.descripcion.trim() ||
      !productData.categoria ||
      !selectedFile
    ) {
      setAlert({
        open: true,
        message: "Por favor, complete todos los campos antes de enviar.",
        severity: "warning",
      });
      return;
    }

    const formData = new FormData();
    formData.append("data", JSON.stringify(productData));
    formData.append("files.image", selectedFile);

    try {
      const response = await fetch("http://localhost:1337/api/productos", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setAlert({
          open: true,
          message: "Producto creado correctamente!",
          severity: "success",
        });
      } else {
        console.error("Failed to create product");
      }
    } catch (error) {
      console.error("Error:", error);
      setAlert({
        open: true,
        message: "Error al crear el producto!",
        severity: "error",
      });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 600,
        margin: "0 auto",
        p: 3,
        [theme.breakpoints.down("sm")]: {
          p: 2,
        },
      }}
    >
      <Header title="Crear Producto" subtitle="Llenar todos los campos" />

      <TextField
        label="Nombre del producto"
        name="nombre"
        value={productData.nombre}
        onChange={handleChange}
        fullWidth
      />

      <TextField
        label="Stock"
        name="stock"
        type="number"
        value={productData.stock}
        onChange={handleChange}
        fullWidth
      />

      <TextField
        label="Descripción"
        name="descripcion"
        value={productData.descripcion}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
      />

      <FormControl fullWidth>
        <InputLabel>Categoría</InputLabel>
        <Select
          name="categoria"
          value={productData.categoria}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Seleccione una categoría</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.attributes.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button variant="contained" component="label">
        Subir Imagen
        <input type="file" hidden onChange={handleFileChange} />
      </Button>

      {previewImage && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Typography variant="subtitle1">
            Vista previa de la imagen:
          </Typography>
          <img
            src={previewImage}
            alt="Vista previa"
            style={{ width: "100%", maxWidth: "300px", marginTop: "10px" }}
          />
        </Box>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ alignSelf: "center", mt: 2 }}
      >
        Crear Producto
      </Button>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Form;
