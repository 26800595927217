import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import CardProduct from "./Productos/CardProduct";
import { createTheme, ThemeProvider } from "@mui/material";
import getLPTheme from "../getLPTheme";

export default function Tienda() {
  const [mode, setMode] = React.useState("dark");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);

  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  return (
    <ThemeProvider theme={!showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <CardProduct />
    </ThemeProvider>
  );
}
