import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views"; // Importa solo SwipeableViews
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import foto7 from "../assets/banner2.png";
import foto8 from "../assets/banner3.png";
import foto9 from "../assets/banner1.png";
import foto11 from "../assets/foto11.jpg";
import fondo from "../assets/fondo.png";
import { keyframes } from "@mui/system";

// Elimina la envoltura con autoPlay
const images = [foto7, foto8, foto9];

// Definir los keyframes para la animación
const zoomIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export default function Hero({ showCarousel }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      id="hero"
      sx={{
        position: "relative",
        width: "100%",
        minHeight: { xs: "50vh", sm: "100vh" },
        backgroundImage: `url(${foto11})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        py: { xs: 8, sm: 10 },
        color: "white",
      }}
    >
      {!showCarousel ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0)",
            zIndex: 1,
          }}
        />
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 1,
          }}
        />
      )}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          zIndex: 1,
        }}
      />
      {!showCarousel ? (
        <Box
          component="img"
          sx={{
            position: "relative",
            zIndex: 2,
            mt: { xs: 8, sm: 5 },
            alignSelf: "center",
            height: { xs: 200, sm: 400, md: 500 },
            width: { xs: 300, sm: 600, md: 800 },
            borderRadius: "10px",
            display: "block",
            margin: "auto",
            animation: `${zoomIn} 1s ease-out`, // Aplica la animación de entrada
          }}
          src={fondo}
          alt="intilight"
        />
      ) : (
        <Container
          sx={{
            position: "relative",
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 15 },
            pb: { xs: 8, sm: 8 },
          }}
        >
          <Stack
            spacing={2}
            useFlexGap
            sx={{ width: { xs: "100%", sm: "70%" } }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginBottom: -2,
              }}
            >
              <Typography
                color="#00B7FF"
                sx={{
                  fontFamily: "FuturaStd-ExtraBold",
                  fontSize: { xs: 100, sm: 80 },
                  marginTop: -13,
                }}
              >
                INTI
              </Typography>
              <Typography
                color="#00B7FF"
                sx={{
                  fontFamily: "FuturaStd-Medium",
                  fontSize: { xs: 100, sm: 80 },
                  marginTop: { xs: 0, sm: -13 },
                  marginLeft: 2,
                }}
              >
                LIGHT EC
              </Typography>
            </Box>
            <Typography
              textAlign="center"
              color="#ffffff"
              fontSize={18}
              sx={{
                fontFamily: "FuturaStd-Medium",
                alignSelf: "center",
                width: { sm: "100%", md: "80%" },
              }}
            >
              Ingeniería en Tecnología de la Información y Sistemas de Energía
              Sustentable
            </Typography>
          </Stack>

          <Box
            id="carousel"
            sx={{
              position: "relative",
              mt: { xs: 8, sm: 5 },
              alignSelf: "center",
              height: { xs: 200, sm: 400, md: 400 },
              width: { xs: 300, sm: 600, md: 800 },
              borderRadius: "10px",
              outline: "1px solid",
              outlineColor: (theme) =>
                theme.palette.mode === "light"
                  ? alpha("#BFCCD9", 0.5)
                  : alpha("#9CCCFC", 0.1),
              boxShadow: (theme) =>
                theme.palette.mode === "light"
                  ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                  : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
            }}
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((image, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        height: { xs: 200, sm: 400 },
                        display: "block",
                        maxWidth: "100%",
                        overflow: "hidden",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                      src={image}
                      alt={`slide ${index}`}
                    />
                  ) : null}
                </div>
              ))}
            </SwipeableViews>
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                position: "absolute",
                top: "50%",
                left: 0,
                transform: "translateY(-50%)",
                zIndex: 3,
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              <KeyboardArrowLeft />
            </Button>
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              sx={{
                position: "absolute",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
                zIndex: 3,
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              <KeyboardArrowRight />
            </Button>
            <Box
              sx={{
                position: "absolute",
                bottom: 8,
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                gap: 1,
              }}
            >
              {images.map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    backgroundColor:
                      index === activeStep ? "blue" : theme.palette.grey[400],
                    transition: "background-color 0.3s",
                  }}
                />
              ))}
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
}
