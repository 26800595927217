import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  Modal,
  TextField,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { CreateOutlined, Close as CloseIcon } from "@mui/icons-material";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [categories, setCategories] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("http://localhost:1337/api/categorias");
        const data = await response.json();
        setCategories(data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Button
          color="error"
          onClick={() => handleOpenConfirmModal(params.row.id)}
        >
          Eliminar
        </Button>
      ),
    },
  ];

  const rows = categories.map((category) => ({
    id: category.id,
    nombre: category.attributes.nombre,
  }));

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setNewCategoryName("");
  };

  const handleCategoryNameChange = (event) =>
    setNewCategoryName(event.target.value);

  const handleCreateCategory = async () => {
    if (!newCategoryName.trim()) {
      setAlert({
        open: true,
        message: "Por favor, llene el campo de nombre de la categoría.",
        severity: "warning",
      });
      return;
    }

    try {
      const response = await fetch("http://localhost:1337/api/categorias", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            nombre: newCategoryName,
          },
        }),
      });

      if (response.ok) {
        const newCategory = await response.json();
        setCategories([...categories, newCategory.data]);
        setAlert({
          open: true,
          message: "¡Categoría creada exitosamente!",
          severity: "success",
        });
        handleCloseModal();
      } else {
        setAlert({
          open: true,
          message: "Error al crear la categoría. Inténtelo nuevamente.",
          severity: "error",
        });
      }
    } catch (error) {
      setAlert({
        open: true,
        message: "Error al crear la categoría. Inténtelo nuevamente.",
        severity: "error",
      });
      console.error("Error creating category:", error);
    }
  };

  const handleOpenConfirmModal = (id) => {
    setCategoryToDelete(id);
    setOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setCategoryToDelete(null);
  };

  const handleConfirmDeleteCategory = async () => {
    if (!categoryToDelete) return;

    try {
      const response = await fetch(
        `http://localhost:1337/api/categorias/${categoryToDelete}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setCategories(
          categories.filter((category) => category.id !== categoryToDelete)
        );
        setAlert({
          open: true,
          message: "Categoría eliminada exitosamente.",
          severity: "success",
        });
      } else {
        setAlert({
          open: true,
          message: "Error al eliminar la categoría. Inténtelo nuevamente.",
          severity: "error",
        });
      }
    } catch (error) {
      setAlert({
        open: true,
        message: "Error al eliminar la categoría. Inténtelo nuevamente.",
        severity: "error",
      });
      console.error("Error deleting category:", error);
    }

    handleCloseConfirmModal();
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Categorias" subtitle="Categorías disponibles" />
        <Box>
          <Button
            onClick={handleOpenModal}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <CreateOutlined sx={{ mr: "10px" }} />
            Crear Categoría
          </Button>
        </Box>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={rows} columns={columns} />
      </Box>

      {/* Modal para crear categoría */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "400px" },
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">Crear Nueva Categoría</Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Nombre de la categoría"
            variant="outlined"
            value={newCategoryName}
            onChange={handleCategoryNameChange}
          />
          <Button
            onClick={handleCreateCategory}
            fullWidth
            sx={{
              mt: 2,
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
            }}
          >
            Crear
          </Button>
        </Box>
      </Modal>

      {/* Modal de confirmación de eliminación */}
      <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "400px" },
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            ¿Estás seguro de que deseas eliminar esta categoría?
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={handleCloseConfirmModal}
              sx={{ mr: 2 }}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleConfirmDeleteCategory}
              color="error"
              variant="contained"
            >
              Eliminar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar para alertas */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Team;
