import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../assets/pdf1.png";

const ResumenDatosCliente = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cliente, data } = location.state;

  const generatePDF = () => {
    const doc = new jsPDF();

    // promedios
    const totalConsumo = data.reduce(
      (total, row) => total + parseFloat(row.consumo || 0),
      0
    );
    const totalPrecio = data.reduce(
      (total, row) => total + parseFloat(row.precio || 0),
      0
    );
    const averageConsumo = (totalConsumo / data.length).toFixed(2);
    const averagePrecio = (totalPrecio / data.length).toFixed(2);

    // Función para dibujar los márgenes rojos en una página
    const drawRedMargins = () => {
      const margin = 10;
      const redMargin = 0.5;
      const contentWidth = doc.internal.pageSize.width - margin * 2;
      const contentHeight = doc.internal.pageSize.height - margin * 2;

      // Dibujar bordes rojos
      doc.setDrawColor(255, 0, 0); // Establecer el color del borde
      doc.setLineWidth(redMargin); // Establecer el ancho del borde

      // Bordes superiores e inferiores
      doc.line(margin, margin, margin + contentWidth, margin); // Borde superior
      doc.line(
        margin,
        margin + contentHeight,
        margin + contentWidth,
        margin + contentHeight
      ); // Borde inferior

      // Bordes laterales
      doc.line(margin, margin, margin, margin + contentHeight); // Borde izquierdo
      doc.line(
        margin + contentWidth,
        margin,
        margin + contentWidth,
        margin + contentHeight
      ); // Borde derecho
    };
    doc.addImage(`${logo}`, "JPEG", 150, 10, 50, 30);

    doc.setTextColor("red");
    doc.setFont("futura-std-extra-bold");
    doc.text("Resumen de Datos del Cliente", 14, 20);
    doc.setTextColor("black");
    doc.text(`Nombre:`, 14, 30);
    doc.text(`${cliente.nombre}`, 90, 30);
    doc.text(`Apellido:`, 14, 40);
    doc.text(`${cliente.apellido}`, 90, 40);
    doc.text(`Provincia:`, 14, 50);
    doc.text(`${cliente.provincia}`, 90, 50);
    doc.text(`Sector:`, 14, 60);
    doc.text(`${cliente.sector}`, 90, 60);
    doc.text(`Dirección:`, 14, 70);
    doc.text(`${cliente.direccion}`, 90, 70);
    doc.text(`Celular:`, 14, 80);
    doc.text(`${cliente.celular}`, 90, 80);

    doc.text("Información de consumo", 100, 90, {
      align: "center",
      fontSize: 24,
      fontStyle: "bold",
      textColor: [255, 0, 0],
    });
    const tableColumn = ["Mes", "Consumo (kW/h)", "Precio Pagado"];
    const tableRows = [];

    data.forEach((row, index) => {
      const rowData = [`${row.mes} ${row.anio}`, row.consumo, row.precio];
      tableRows.push(rowData);
    });

    // Agregar una fila con los totales al final de la tabla
    tableRows.push(["Total", totalConsumo.toFixed(2), totalPrecio.toFixed(2)]);

    doc.autoTable(tableColumn, tableRows, { startY: 100 });

    // Adding second page
    doc.addPage();

    doc.text("CALCULOS MENSUALES", 14, 20);

    const whMes = (averageConsumo * 1000).toFixed(2);
    const whDia = (whMes / 30).toFixed(2);

    const promedioTable = [
      "Consumo (kWh/M)",
      "Consumo (Wh/M)",
      "Consumo (Wh/Día)",
      "Precio Pagado",
    ];
    const tableRows1 = [];

    tableRows1.push([averageConsumo, whMes, whDia, averagePrecio]);

    doc.autoTable(promedioTable, tableRows1, { startY: 30 });

    doc.text("Con sistema fotovoltaico de INTILIGHT", 14, 60);
    //calculos con paneles
    const whPanel = 600;
    const whDiaPanel = (whPanel * 4.5).toFixed(2); // horas del dia
    const whMesPanel = (whDiaPanel * 30).toFixed(2); // genera al mes wats hora /mes
    const kwhMes = (whMesPanel / 1000).toFixed(2); //kilowat hora al mes

    const tablaPanel = [
      "Consumo (kWh/M)",
      "Consumo (Wh/M)",
      "Consumo (Wh/Día)",
    ];
    const tablaPanel1 = [];

    tablaPanel1.push([kwhMes, whMesPanel, whDiaPanel]);

    doc.autoTable(tablaPanel, tablaPanel1, { startY: 70 });

    //Otrooo

    doc.text("Sus ahorros serian", 14, 95);

    const dia = (whDia - whDiaPanel).toFixed(2);
    const meswh = (whMes - whMesPanel).toFixed(2);
    const mesKwh = (averageConsumo - kwhMes).toFixed(2);

    const tablaAhorro = [
      "Consumo (kWh/M)",
      "Consumo (Wh/M)",
      "Consumo (Wh/Día)",
    ];
    const tablaAhorro1 = [];

    tablaAhorro1.push([mesKwh, meswh, dia]);

    doc.autoTable(tablaAhorro, tablaAhorro1, { startY: 110 });

    doc.save("resumen_datos_cliente.pdf");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      minHeight="100vh"
      p={4}
    >
      <Typography variant="h4" mb={4}>
        Resumen de Datos del Cliente
      </Typography>
      <Box mb={4} width="100%">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Nombre"
              value={cliente.nombre}
              fullWidth
              margin="normal"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Apellido"
              value={cliente.apellido}
              disabled
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Provincia"
              value={cliente.provincia}
              disabled
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Sector"
              value={cliente.sector}
              disabled
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Dirección"
              value={cliente.direccion}
              disabled
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Celular"
              value={cliente.celular}
              disabled
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h4" mb={4}>
        Consumo de energía
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mes</TableCell>
              <TableCell align="right">Consumo (kW/h)</TableCell>
              <TableCell align="right">Precio Pagado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{`${row.mes} ${row.anio}`}</TableCell>
                <TableCell align="right">{row.consumo}</TableCell>
                <TableCell align="right">{row.precio}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={generatePDF}>
          Descargar PDF
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate(-1)}
          style={{ marginLeft: 10 }}
        >
          Volver
        </Button>
      </Box>
    </Box>
  );
};

export default ResumenDatosCliente;
