import React from "react";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";

const Product = ({ product }) => {
  const image = product.attributes.image.data[0].attributes;

  return (
    <Card sx={{ maxWidth: 345, m: 2, textTransform: "capitalize"}}>
      <CardMedia
        component="img"
        height="200"
        image={`http://localhost:1337${image.url}`}
        alt={image.name}
      />
      <CardContent>
        <Typography variant="h5" component="div" sx={{fontFamily:"FuturaStd-Extrabold"}}>
          {product.attributes.nombre}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{fontFamily:"FuturaStd-Medium"}}>
          {product.attributes.descripcion}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Product;
