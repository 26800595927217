import React, { useContext, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { format, subMonths } from "date-fns";
import { useNavigate } from "react-router-dom";
import provinciasYsectores from "./provinciasYsectores";
import { AuthContext } from "../Context/AuthContext";

const DatosCliente = () => {
  const [cliente, setCliente] = useState({
    nombre: "",
    apellido: "",
    provincia: "",
    sector: "",
    direccion: "",
    celular: "",
  });
  const [data, setData] = useState(
    Array(12)
      .fill({ consumo: "", precio: "" })
      .map(() => ({ consumo: "", precio: "", mes: "", anio: "" }))
  );
  const [loading, setLoading] = useState(false);
  const { register } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClienteChange = (field, value) => {
    setCliente({ ...cliente, [field]: value });
    if (field === "provincia") {
      setCliente({ ...cliente, provincia: value, sector: "" });
    }
  };

  const handleInputChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  const calculateSums = () => {
    const totalConsumo = data.reduce(
      (acc, curr) => acc + (parseFloat(curr.consumo) || 0),
      0
    );
    const totalPrecio = data.reduce(
      (acc, curr) => acc + (parseFloat(curr.precio) || 0),
      0
    );
    console.log("Total Consumo: ", totalConsumo);
    console.log("Total Precio: ", totalPrecio);
  };

  const handleSubmit = () => {
    console.log("Cliente: ", cliente);
    calculateSums();
    register(cliente, setLoading);
    navigate("/admin/dashboard/create-project/resumen", { state: { cliente, data } });
  };

  const renderInputs = () => {
    const inputs = [];
    const currentDate = new Date();

    for (let i = 0; i < 12; i++) {
      const startDate = subMonths(currentDate, i + 1);
      const endDate = subMonths(currentDate, i);
      const monthYear = `${format(startDate, "MMM yyyy")}`;

      inputs.push(
        <Box key={i}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={5}>
              <TextField
                label={`Consumo kW/h ${monthYear}`}
                type="number"
                value={data[i].consumo}
                onChange={(e) =>
                  handleInputChange(i, "consumo", e.target.value)
                }
                fullWidth
                margin="normal"
                size="medium"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={`Precio pagado ${monthYear}`}
                type="number"
                value={data[i].precio}
                onChange={(e) => handleInputChange(i, "precio", e.target.value)}
                fullWidth
                margin="normal"
                size="medium"
              />
            </Grid>
          </Grid>
        </Box>
      );

      // Store month and year in the data state
      data[i].mes = format(startDate, "MMM");
      data[i].anio = format(startDate, "yyyy");
    }
    return inputs.reverse();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      marginTop={5}
    >
      <Box width="50%">
        <Typography variant="h4" textAlign="center">
          Datos del Cliente
        </Typography>
        <Grid container spacing={2} mb={2} justifyContent="center">
          <Grid item xs={5}>
            <TextField
              label="Nombre"
              type="text"
              value={cliente.nombre}
              onChange={(e) => handleClienteChange("nombre", e.target.value)}
              fullWidth
              margin="normal"
              size="medium"
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Apellido"
              type="text"
              value={cliente.apellido}
              onChange={(e) => handleClienteChange("apellido", e.target.value)}
              fullWidth
              margin="normal"
              size="medium"
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              select
              label="Provincia"
              value={cliente.provincia}
              onChange={(e) => handleClienteChange("provincia", e.target.value)}
              fullWidth
              margin="normal"
              size="medium"
            >
              {Object.keys(provinciasYsectores).map((provincia) => (
                <MenuItem key={provincia} value={provincia}>
                  {provincia}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField
              select
              label="Sector"
              value={cliente.sector}
              onChange={(e) => handleClienteChange("sector", e.target.value)}
              fullWidth
              margin="normal"
              size="medium"
              disabled={!cliente.provincia}
            >
              {cliente.provincia &&
                provinciasYsectores[cliente.provincia].map((sector) => (
                  <MenuItem key={sector} value={sector}>
                    {sector}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Dirección"
              type="text"
              value={cliente.direccion}
              onChange={(e) => handleClienteChange("direccion", e.target.value)}
              fullWidth
              margin="normal"
              size="medium"
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Celular"
              type="number"
              value={cliente.celular}
              onChange={(e) => handleClienteChange("celular", e.target.value)}
              fullWidth
              margin="normal"
              size="medium"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={1} justifyContent="center">
          <Grid item xs={5}>
            <Typography variant="h4" align="left">
              Consumo
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4" align="left">
              Pago EEQ
            </Typography>
          </Grid>
        </Grid>

        {renderInputs()}
        <Box display="flex" justifyContent="center" mt={2} mb={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Siguiente"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DatosCliente;
