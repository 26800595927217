import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const navigate = useNavigate();

  // Función para cerrar el Snackbar
  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  //login
  const login = (identifier, password, setLoading) => {
    setLoading(true);
    axios
      .post("http://localhost:1337/api/auth/local", {
        identifier,
        password,
      })
      .then((res) => {
        localStorage.setItem("userToken", res.data.jwt);
        let userInfo = res.data;
        setUserToken(userInfo.jwt);

        setAlert({
          open: true,
          message: "¡BIENVENIDO!",
          severity: "success",
        });
        navigate("/admin/dashboard");
        console.log("userToken: ", userInfo.jwt);
        setLoading(false);
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Email o contraseña incorrecta!",
          severity: "error",
        });
        console.log("error al iniciar sesión");
        setLoading(false);
      });
  };

  //registro de clientes
  const register = (cliente, setLoading) => {
    setLoading(true);
    axios
      .post(
        "http://localhost:1337/api/clientes",
        {
          data: cliente,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        console.log("registro exitoso");
        setLoading(false);
      })
      .catch((err) => {
        console.log("error al registrar");
        setLoading(false);
      });
  };

  //cerrar sesion
  const logout = () => {
    setUserToken(null);
    localStorage.removeItem("userToken");
  };

  const isLoggedIn = async () => {
    try {
      let userToken = await localStorage.getItem("userToken");
      setUserToken(userToken);
    } catch (error) {
      console.log("isLogged error: ", error);
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, [userToken]);

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        logout,
        userToken,
      }}
    >
      {children}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};
