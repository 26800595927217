import React, { useState, useEffect } from "react";
import Product from "./Product";
import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  Button,
  Box,
} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store"; // Importa el icono de tienda
import { useProducts } from "./useProducts";
import { keyframes } from "@emotion/react"; // Importa keyframes para la animación

// Definir la animación de arriba hacia abajo
const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);  // Posición inicial y final
  }
  50% {
    transform: translateY(-10px); // Mueve el icono hacia arriba 10px
  }
`;

const CardProduct = () => {
  const { categories, products, loading } = useProducts();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Filtrar productos según la categoría seleccionada
  useEffect(() => {
    if (selectedCategory) {
      setFilteredProducts(
        products.filter(
          (product) => product.attributes.categoria.data.id === selectedCategory
        )
      );
    } else {
      setFilteredProducts(products);
    }
  }, [selectedCategory, products]);

  return (
    <Container
      id="tienda"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "center", md: "center" },
          fontFamily: "FuturaStd-Extrabold",
          display: "flex", // Añade flex para alinear el icono y el texto
          alignItems: "center", // Centra verticalmente el contenido
          justifyContent: "center", // Centra horizontalmente el contenido
        }}
      >
        <StoreIcon
          sx={{
            color:"primary.main",
            mr: 1,
            animation: `${bounceAnimation} 2s infinite`, // Aplica la animación
          }}
        />{" "}
        {/* Añade el icono aquí con margen a la derecha */}
        Tienda
      </Typography>
      <Box sx={{ width: "100%" }}>
        {loading ? ( // Mostrar el cargador si los productos están cargando
          <CircularProgress style={{ display: "block", margin: "auto" }} />
        ) : (
          <div>
            {/* Botones de Categorías */}
            <Grid container spacing={2} justifyContent="center">
              {categories.map((category) => (
                <Grid item key={category.id}>
                  <Button
                    variant={
                      selectedCategory === category.id
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setSelectedCategory(category.id)}
                    sx={{
                      fontFamily: "FuturaStd-Medium",
                    }}
                  >
                    {category.attributes.nombre}
                  </Button>
                </Grid>
              ))}
              <Grid item>
                <Button
                  variant={!selectedCategory ? "contained" : "outlined"}
                  onClick={() => setSelectedCategory(null)}
                  sx={{
                    fontFamily: "FuturaStd-Medium",
                  }}
                >
                  Todos los productos
                </Button>
              </Grid>
            </Grid>

            {/* Mostrar Productos */}
            <Grid
              container
              spacing={4}
              justifyContent="center"
              style={{ marginTop: "20px" }}
            >
              {filteredProducts.map((product) => (
                <Grid item key={product.id} xs={12} sm={6} md={4}>
                  <Product product={product} />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </Box>
    </Container>
  );
};

export default CardProduct;
