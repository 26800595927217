import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import EdgesensorHighRoundedIcon from "@mui/icons-material/EdgesensorHighRounded";
import foto1 from "../assets/foto1.jpg";
import foto4 from "../assets/offGrid.png";
import foto3 from "../assets/onGrid.png";
import {
  MonetizationOnRounded,
  MoodBadRounded,
  SolarPowerRounded,
} from "@mui/icons-material";

const items = [
  {
    icon: <MonetizationOnRounded />,
    title: "SISTEMAS ON GRID",
    description:
      "Inyeccion de energía eléctrica al sistema interconectado - Te permitirá ahorrar dinero",
    imageLight: `url(${foto3})`,
    imageDark: `url(${foto3})`,
    texto:
      "Un sistema On Grid hace referencia al tipo de instalación que se encuentra conectado a la red de distribución, permitiendo que los clientes puedan generar y consumir energía solar, pero con el respaldo de la red eléctrica en casos de que su empresa o residencia consuma más energía de la generada por los paneles.",
  },
  {
    icon: <MoodBadRounded />,
    title: "SISTEMAS OFF GRID",
    description: "Ahorro de energia eléctrica, almacenando tu propia energía - Ideal para cortes de luz",
    imageLight: `url(${foto4})`,
    imageDark: `url(${foto4})`,
    texto:
      "Un sistema Off Grid, es un sistema de generación fotovoltaica autónomo que no se encuentra conectado a la red eléctrica. Este sistema se compone por paneles solares,  regulador de carga, un inversor y baterías solares. Con este equipamiento obtendrás un sistema de generación de energía eléctrica aislado, sin necesidad de la conexión a la red Eléctrica.",
  },
  {
    icon: <SolarPowerRounded />,
    title: "KITS SOLARES AJUSTADOS A CONSUMOS ESPECIFICOS",
    description: "Inversores, Reguladores y baterías",
    imageLight: `url(${foto1})`,
    imageDark: `url(${foto1})`,
    texto:
      "Nuestros kits solares están diseñados para ofrecer una solución confiable durante los cortes de luz. Cada kit incluye un inversores, reguladores, y baterías, lo que garantiza una carga óptima y un suministro continuo de energía para tus dispositivos esenciales.",
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const [showAdditionalTextIndex, setShowAdditionalTextIndex] =
    React.useState(null); // Índice del texto adicional

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
    setShowAdditionalTextIndex(null); // Reiniciar el texto adicional al cambiar de elemento
  };

  const handleToggleAdditionalText = (index, event) => {
    event.stopPropagation(); // Evita que el clic se propague y cambie el elemento seleccionado
    setShowAdditionalTextIndex(
      showAdditionalTextIndex === index ? null : index
    ); // Alterna la visibilidad del texto adicional
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 5, sm: 12 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography
              color="text.primary"
              sx={{ fontFamily: "FuturaStd-ExtraBold", fontSize: 30 }}
            >
              Nuestros Servicios
            </Typography>
            <Typography
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 }, fontFamily: "FuturaStd-Medium" }}
            >
              Tu requerimiento de energía sustentable debe basarse en los
              siguientes conceptos, por lo que es necesario que tengas en cuenta
              que tipo de Sistema se adecua a tu necesidad:
            </Typography>
          </div>
          <Grid
            container
            item
            gap={1}
            sx={{ display: { xs: "auto", sm: "none" } }}
          >
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  fontFamily: "FuturaStd-ExtraBold",
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index ? "primary.light" : "";
                    }
                    return selectedItemIndex === index ? "primary.light" : "";
                  },
                  background: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index ? "none" : "";
                    }
                    return selectedItemIndex === index ? "none" : "";
                  },
                  backgroundColor:
                    selectedItemIndex === index ? "primary.main" : "",
                  "& .MuiChip-label": {
                    color: selectedItemIndex === index ? "#fff" : "",
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: "auto", sm: "none" },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === "light"
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography
                color="text.primary"
                variant="body2"
                fontWeight="bold"
                sx={{ fontFamily: "FuturaStd-ExtraBold" }}
              >
                {selectedFeature.title}
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                sx={{ my: 0.5, fontFamily: "FuturaStd-Medium" }}
              >
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                sx={{
                  fontFamily: "FuturaStd-Medium",
                  display: "inline-flex",
                  alignItems: "center",
                  "& > svg": { transition: "0.2s" },
                  "&:hover > svg": { transform: "translateX(2px)" },
                }}
                onClick={(event) =>
                  handleToggleAdditionalText(selectedItemIndex, event)
                } // Añadido el índice al evento onClick
              >
                <span>Ver más</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: "1px", ml: "2px" }}
                />
              </Link>
              {/* Renderiza el texto adicional condicionalmente */}
              {showAdditionalTextIndex === selectedItemIndex && (
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ mt: 2, fontFamily: "FuturaStd-Medium" }}
                >
                  {selectedFeature.texto} {/* Texto adicional específico */}
                </Typography>
              )}
            </Box>
          </Box>

          {/*  OTROOOOO */}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                  background: "none",
                  backgroundColor:
                    selectedItemIndex === index ? "action.selected" : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index
                        ? "primary.light"
                        : "grey.200";
                    }
                    return selectedItemIndex === index
                      ? "primary.dark"
                      : "grey.800";
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === "light") {
                          return selectedItemIndex === index
                            ? "primary.main"
                            : "grey.300";
                        }
                        return selectedItemIndex === index
                          ? "primary.main"
                          : "grey.700";
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: "none" }}>
                    <Typography
                      sx={{ fontFamily: "FuturaStd-ExtraBold", fontSize: 15 }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      sx={{
                        my: 0.5,
                        fontFamily: "FuturaStd-Medium",
                        fontSize: 15,
                      }}
                    >
                      {description}
                    </Typography>
                    <Link
                      color="primary"
                      sx={{
                        fontFamily: "FuturaStd-Medium",
                        fontSize: 15,
                        display: "inline-flex",
                        alignItems: "center",
                        "& > svg": { transition: "0.2s" },
                        "&:hover > svg": { transform: "translateX(2px)" },
                      }}
                      onClick={(event) =>
                        handleToggleAdditionalText(index, event)
                      } // Añadido el índice al evento onClick
                    >
                      <span>Ver más</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: "1px", ml: "2px" }}
                      />
                    </Link>
                    {/* Renderiza el texto adicional condicionalmente */}
                    {showAdditionalTextIndex === index && (
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{ mt: 2, fontFamily: "FuturaStd-Medium" }}
                      >
                        {items[index].texto} {/* Texto adicional específico */}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", sm: "flex" },
            width: "100%",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "120%",
              display: { xs: "none", sm: "flex" },
              pointerEvents: "none",
              fontFamily: "FuturaStd-Medium",
            }}
          >
            <Box
              sx={{
                m: "auto",
                width: 550,
                height: 500,
                backgroundSize: "100% 100%",
                backgroundImage: (theme) =>
                  theme.palette.mode === "light"
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
