// provinciasYsectores.js
const provinciasYsectores = {
  Azuay: ["Cuenca", "Gualaceo", "Nabón", "Paute", "Sígsig"],
  Bolívar: ["Guaranda", "Chimbo", "Echeandía", "San Miguel", "Las Naves"],
  Cañar: ["Azogues", "Biblián", "Cañar", "La Troncal", "El Tambo"],
  Carchi: ["Tulcán", "Montúfar", "Mira", "Bolívar", "Espejo"],
  Chimborazo: ["Riobamba", "Guano", "Chambo", "Penipe", "Guamote"],
  Cotopaxi: ["Latacunga", "Salcedo", "Saquisilí", "Pujilí", "La Maná"],
  "El Oro": ["Machala", "Arenillas", "Huaquillas", "Pasaje", "Santa Rosa"],
  Esmeraldas: [
    "Esmeraldas",
    "Atacames",
    "Eloy Alfaro",
    "Quinindé",
    "San Lorenzo",
  ],
  Galápagos: ["Puerto Ayora", "Puerto Baquerizo Moreno", "Puerto Villamil"],
  Guayas: ["Guayaquil", "Daule", "Durán", "Milagro", "Samborondón"],
  Imbabura: ["Ibarra", "Otavalo", "Cotacachi", "Antonio Ante", "Pimampiro"],
  Loja: ["Loja", "Catamayo", "Calvas", "Puyango", "Saraguro"],
  "Los Ríos": ["Babahoyo", "Quevedo", "Ventanas", "Vinces", "Buena Fe"],
  Manabí: ["Portoviejo", "Manta", "Chone", "Bahía de Caráquez", "Jipijapa"],
  "Morona Santiago": ["Macas", "Gualaquiza", "Sucúa", "Logroño", "Taisha"],
  Napo: [
    "Tena",
    "Archidona",
    "El Chaco",
    "Quijos",
    "Carlos Julio Arosemena Tola",
  ],
  Orellana: ["Puerto Francisco de Orellana", "Joya de los Sachas", "Loreto"],
  Pastaza: ["Puyo", "Mera", "Santa Clara", "Arajuno"],
  Pichincha: ["Quito", "Cayambe", "Mejía", "Pedro Moncayo", "Rumiñahui"],
  "Santa Elena": ["Santa Elena", "Salinas", "La Libertad"],
  "Santo Domingo de los Tsáchilas": ["Santo Domingo", "La Concordia"],
  Sucumbíos: [
    "Nueva Loja",
    "Shushufindi",
    "Lago Agrio",
    "Cascales",
    "Putumayo",
  ],
  Tungurahua: [
    "Ambato",
    "Baños de Agua Santa",
    "Pelileo",
    "Píllaro",
    "Cevallos",
  ],
  "Zamora Chinchipe": [
    "Zamora",
    "Yantzaza",
    "El Pangui",
    "Centinela del Cóndor",
    "Palanda",
  ],
};

export default provinciasYsectores;
